import instance from './instance'

export const createLead = async (payload: any) => { 
  return await instance.post('modules-lead/leads', payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
    if (err.response) {
      return err.response.data
    }
  }
  )
}



export const getLeadList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`modules-lead/leads?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateLead = async (id: any, payload: any) =>{
  return await instance.post(`modules-lead/leads/${id}`, payload).then((response:any) => {
    if (response) {
      return response
    }
  }).catch((err:any) => {
  
    if (err.response) {
      console.log(err.response.data)
      return err.response
    }
  }
  )

}

export const deleteLead = (id: any) => instance.delete(`modules-lead/leads/${id}`)

export const getLeadById = (id: number) => instance.get(`modules-lead/leads/${id}`)

// -------------------- Assign Lead To user ---------------------

export const createAssignLead = (payload: any) => instance.post('modules-lead/usersAssigned', payload)

export const deleteAssignLead = (id:any) => instance.delete(`modules-lead/usersAssigned/${id}`)

// export const getAssignLeadList = (page: number, pageSize: number, filterkey: any) =>
//   instance.get(`modules-lead/usersAssigned?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

// export const updateAssignLead = (id: any, payload: any) => instance.post(`modules-lead/usersAssigned/${id}`, payload)

// export const getAssignLeadById = (id: number) => instance.get(`modules-lead/usersAssigned/${id}`)


// ----------------------- discussion ---------------------------


export const createLeadDiscussion = (payload: any) => instance.post('modules-lead/leaddiscussion', payload)

export const deleteLeadDiscussion = (id: any) => instance.delete(`modules-lead/leaddiscussion/${id}`)

// export const getLeadDiscussionList = () => instance.get(`modules-lead/leaddiscussion`)

// export const updateLeadDiscussion = (id: any, payload: any) => instance.post(`modules-lead/leaddiscussion/${id}`, payload)

// export const getLeadDiscussionById = (id: number) => instance.get(`modules-lead/leaddiscussion/${id}`)

// -------------------------- tags ------------------------------

export const getTagsList = () => instance.get(`modules-lead/tags`)

export const createTags = (payload: any) => instance.post('modules-lead/tags', payload)

export const deleteTags = (id: any) => instance.delete(`modules-lead/tags/${id}`)

export const updateTags = (id: any, payload: any) => instance.post(`modules-lead/tags/${id}`, payload)

export const getTagsById = (id: number) => instance.get(`modules-lead/tags/${id}`)

// -------------------------- calls ------------------------------

export const getCallsList = () => instance.get(`modules-lead/calls`)

export const createCalls = (payload: any) => instance.post('modules-lead/calls', payload)

export const deleteCalls = (id: any) => instance.delete(`modules-lead/calls/${id}`)

export const updateCalls = (id: any, payload: any) => instance.post(`modules-lead/calls/${id}`, payload)

export const getCallsById = (id: number) => instance.get(`modules-lead/calls/${id}`)


// --------------------------File 

export const UploadFile = (payload: any) => instance.post('modules-lead/files', payload)
export const DeleteFile = (id: any) => instance.delete(`modules-lead/files/${id}`)
