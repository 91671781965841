import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { TablesWidget } from '../../../../_metronic/partials/widgets'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UploadFileApi, createLeadUserApi, deleteCallLeadApi, deleteFileLeadApi, deleteLeadDiscussionApi, deleteLeadUserApi, getLeadByIdApi, updateLeadApi } from '../../../redux/slices/LeadSlices'
import { DateFormat } from '../../../../_metronic/helpers/components/DateFormat'
import { UserForm } from './UserForm'
import { getAllUserListApi } from '../../../redux/slices/UserSlices'
import { DiscussionForm } from './DiscussionForm'
import { toast } from 'react-toastify'
import { deleteAssignLead } from '../../../services/LeadService'
import { CallForm } from './CallForm'
import { ConfirmationModal } from '../../../../_metronic/partials/widgets/modals/ConfirmationModal'
import { FaRegSave } from "react-icons/fa";
//Your initialization


const LeadDetails: FC = () => {
  const leadDetailsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]



  // --------------- Fetch Lead By Id --------------------

  // ----------------------------------------------------

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDiscussionModalOpen, setDiscussionModel] = useState(false)
  const [isCallModelOpen, setCallModel] = useState(false)
  const [isCallDeleteOpen, setCallDeleteModel] = useState(false)
  const [callSelectedId, setCallSelectedId] = useState<any>(false)
  const [isFileDeleteOpen, setFileDeleteModel] = useState(false)
  const [fileSelectedId, setFileSelectedId] = useState<any>(false)
  const [loading, setLoading] = useState(false)
  const LeadData = useSelector((state: any) => state?.lead?.LeadData?.data?.data)
  const UpdateLeadData = useSelector((state: any) => state?.lead?.updateLeadData?.data?.data)

  
  const [note,setNotes] = useState<any>(null)


  const userCreated = useSelector((state: any) => state?.lead?.userCreated?.data?.data)
  const userDeleted = useSelector((state: any) => state?.lead?.userDeleted?.data?.data)

  const discussionCreated = useSelector((state: any) => state?.lead?.discussionCreated?.data?.data)
  const discussionDeleted = useSelector((state: any) => state?.lead?.discussionDeleted?.data?.data)
  const callCreated = useSelector((state: any) => state?.lead?.callCreated?.data?.data)
  const callDeleted = useSelector((state: any) => state?.lead?.callDeleted?.data?.data)
  const fileuploaded = useSelector((state: any) => state?.lead?.fileuploaded?.data?.data)
  const fileDeleted = useSelector((state: any) => state?.lead?.fileDeleted?.data?.data)




  // State to track the active section
  const [activeSection, setActiveSection] = useState('')
  const [y, setY] = useState(window.scrollY);
  // Add event listener on component mount

  const dispatch = useDispatch<any>()
  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()
  let location = useLocation();





  useEffect(() => {
    if (id)
      fetchLeadById()
  }, [id, UpdateLeadData,userCreated, userDeleted, discussionCreated, discussionDeleted, callCreated, callDeleted, fileuploaded, fileDeleted])



  const fetchLeadById = () => {
    dispatch(getLeadByIdApi(id))
  }

  useEffect(()=>{
    if(LeadData)
    setNotes(LeadData.note)

    // console.log(LeadData.note,note)
  },[LeadData])

  // Use Ref to get references to section elements
  const generalSectionRef = useRef<HTMLDivElement>(null)
  const userSectionRef = useRef<HTMLDivElement>(null)
  const discussionSectionRef = useRef<HTMLDivElement>(null)
  // const notesSectionRef = useRef<HTMLDivElement>(null)
  const callsSectionRef = useRef<HTMLDivElement>(null)
  // const activitySectionRef = useRef<HTMLDivElement>(null)
  // const fileSectionRef = useRef<HTMLDivElement>(null)
  // ... add similar refs for other sections ...

  // Store the section refs in an object
  const sectionRefs = {
    general: generalSectionRef,
    user: userSectionRef,
    discussion: discussionSectionRef,
    // notes: notesSectionRef,
    // files: fileSectionRef,
    calls: callsSectionRef,
    // activity: activitySectionRef,
    // ... add similar refs for other sections ...
  }



  // Function to handle scroll events
  const handleNavigation = useCallback(
    (e: any) => {

      const window = e.currentTarget;
      let IsUp = false
      if (y > window.scrollY) {
        IsUp = true
      } else if (y < window.scrollY) {
        IsUp = false
      }
      setY(window.scrollY);

      for (const [sectionId, sectionRef] of Object.entries(sectionRefs)) {
        if (sectionRef.current) {

          const rect = sectionRef.current.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            if (IsUp) {
              setActiveSection(sectionId)
              break;
            } else {
              setActiveSection(sectionId)
            }
          }
        }
      }
    }, [y]
  );



  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    // window.addEventListener('click', function(e){   
    //   const node :any = document.getElementById('inputNotes')
    //   if (node.contains(e.target)){
    //     // Clicked in box

    //   } else{
    //     updateLead()
    //     // Clicked outside the box
    //   }
    // });

    // function beforeUnload(e: BeforeUnloadEvent) {
    //   if (!pending) return;
    //   e.preventDefault();
    // }
  
    // window.addEventListener('beforeunload', beforeUnload);
  
    // return () => {
    //   window.removeEventListener('beforeunload', beforeUnload);
    // };


    return () => {
      window.removeEventListener("scroll", handleNavigation);

    };



  }, [handleNavigation]);



  // ------------------------------------------------------

  const columnData = [
    { name: 'SUBJECT', selector: (row: any) => row?.subject, sortable: true },
    { name: 'CALL TYPE', selector: (row: any) => row?.call_type, sortable: true },
    { name: 'DURATION', selector: (row: any) => row?.duration, sortable: true },
    // { name: 'USER', selector: (row: any) => row?.current_status, sortable: true },

    {
      name: 'ACTION',
      cell: (row: any) => (
        <div className='d-flex'>
          {/* <div
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          // onClick={() => handleEdit(row?.id)}
          >
            <KTIcon iconName='pencil' className='fs-3' />
          </div> */}

          <div
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={e => { setCallSelectedId(row?.id); setCallDeleteModel(true) }}
          >
            <KTIcon iconName='trash' className='fs-3' />
          </div>
        </div>
      ),
      sortable: false,
      style: { textAlign: 'left' },
    },
  ]

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [TotalData, setTotalData] = useState(10)

  // ------------------------------------------------------

  const handlePageChange = (page: any) => {
    setCurrentPage(page)
  }

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setCurrentPage(page)
    setRowsPerPage(newPerPage)
  }
  // const [activeSection, setActiveSection] = useState('general')

  const handleSectionClick = (sectionId: string) => {
    document.getElementById(sectionId)?.scrollIntoView()
    setActiveSection(sectionId)
  }


  const openUserModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openDiscussionModal = () => {
    setDiscussionModel(true)
  }

  const closeDiscussionModel = () => {
    setDiscussionModel(false)
  }

  const openCallModal = () => {
    setCallModel(true)
  }

  const closeCallModel = () => {
    setCallModel(false)
  }

  function closeCallDeleteModel() {
    setCallSelectedId(null)
    setCallDeleteModel(false)
  }

  function closeFileDeleteModel() {
    setFileSelectedId(null)
    setFileDeleteModel(false)
  }



  const OnUserDelete = async (data: any) => {

    const result = await dispatch(deleteLeadUserApi(data.id))

    console.log(result)

    if (result.payload?.data.success) {
      toast.success(result.payload.data.reason)
    }
    else {
      if (typeof result.payload.data.reason == 'string') {
        toast.error(result.payload.data.reason)
      }
      else {
        const reason = Object.values(result.payload.data.reason)
        reason.map((item: any) => {
          toast.error(item)
        })
      }
    }
  }


  const OnDiscussionDeleteClick = async (id: any) => {

    const result = await dispatch(deleteLeadDiscussionApi(id))

    console.log(result)

    if (result.payload?.data.success) {
      toast.success(result.payload.data.reason)
    }
    else {
      if (typeof result.payload.data.reason == 'string') {
        toast.error(result.payload.data.reason)
      }
      else {
        const reason = Object.values(result.payload.data.reason)
        reason.map((item: any) => {
          toast.error(item)
        })
      }
    }

  }



  const OnCallDeleteClick = async () => {
    const result = await dispatch(deleteCallLeadApi(callSelectedId))

    console.log(result)

    if (result.payload?.data.success) {
      toast.success(result.payload.data.reason)
    }
    else {
      if (typeof result.payload.data.reason == 'string') {
        toast.error(result.payload.data.reason)
      }
      else {
        const reason = Object.values(result.payload.data.reason)
        reason.map((item: any) => {
          toast.error(item)
        })
      }
    }
  }


  async function uploadFile(file: any) {

    file = file.target.files[0]
    const form = new FormData()
    form.append("lead_id", LeadData.id)
    form.append("filename", file)

    const result = await dispatch(UploadFileApi(form))



    if (result.payload?.data.success) {
      toast.success(result.payload.data.reason)
    }
    else {
      if (typeof result.payload.data.reason == 'string') {
        toast.error(result.payload.data.reason)
      }
      else {
        const reason = Object.values(result.payload.data.reason)
        reason.map((item: any) => {
          toast.error(item)
        })
      }
    }
  }

  const OnFileDelete = async () => {
    const result = await dispatch(deleteFileLeadApi(fileSelectedId))

    console.log(result)

    if (result.payload?.data.success) {
      toast.success(result.payload.data.reason)
    }
    else {
      if (typeof result.payload.data.reason == 'string') {
        toast.error(result.payload.data.reason)
      }
      else {
        const reason = Object.values(result.payload.data.reason)
        reason.map((item: any) => {
          toast.error(item)
        })
      }
    }
  }


  async function updateLead() {
    // const noteNode: any = document.getElementById("inputNotes")

    //  console.log(LeadData?.note?.length ,noteNode?.value?.length)
    
      const payload = {
        ...LeadData,
      }

      payload["note"] = note
      console.log(payload)

      const result = await dispatch(updateLeadApi({ id, payload }))


      console.log(result)

      if (result.payload?.data.success) {
        toast.success(result.payload.data.reason)
      }
      else {
        if (typeof result.payload.data.reason == 'string') {
          toast.error(result.payload.data.reason)
        }
        else {
          const reason = Object.values(result.payload.data.reason)
          reason.map((item: any) => {
            toast.error(item)
          })
        }
      }

    
  }


  function isNoteChange() {

    let node: any = document.getElementById("inputNotes")

    // console.log(LeadData?.note?.lengt !== node?.value?.length)
    if (LeadData?.note != node?.value) {
      return true
    }
    else {
      return false

    }
  }



  return (
    <>
      <PageTitle breadcrumbs={leadDetailsBreadCrumbs}>{LeadData?.name}</PageTitle>
      {isModalOpen ? <UserForm open={isModalOpen} handleClose={closeModal} leadData={LeadData} /> : null}
      {isDiscussionModalOpen ? <DiscussionForm open={isDiscussionModalOpen} handleClose={closeDiscussionModel} leadData={LeadData} /> : null}
      {isCallModelOpen ? <CallForm open={isCallModelOpen} handleClose={closeCallModel} leadData={LeadData} /> : null}

      <ConfirmationModal
        icon='/media/icons/duotune/general/gen027.svg'
        title='Are you sure?'
        description='Are you sure you want to Delete!'
        onSave={OnCallDeleteClick}
        open={isCallDeleteOpen}
        handleClose={closeCallDeleteModel}
      />

      <ConfirmationModal
        icon='/media/icons/duotune/general/gen027.svg'
        title='Are you sure?'
        description='Are you sure you want to Delete!'
        onSave={OnFileDelete}
        open={isFileDeleteOpen}
        handleClose={closeFileDeleteModel}
      />

      <div className='row' >
        <div className='col-12 mb-3'>
          <div className='row col-md'>
            <div className='col-md-3 mb-5 d-none d-md-block'>
              <div className='card sticky-top ' style={{ top: 30,zIndex:"99" }}>
                <div className='list-group list-group-flush d-flex flex-md-column' id='useradd-sidenav'>
                  <div
                    className={`list-group-item list-group-item-action px-6 py-4 border-0 ${activeSection === 'general' ? 'active' : ''
                      }`}
                    onClick={() => handleSectionClick('general')}
                  >
                    General
                    <div className='float-end'>
                      <i className='ti ti-chevron-right' />
                    </div>
                  </div>
                  <div
                    className={`list-group-item list-group-item-action px-6 py-4 border-0 ${activeSection === 'user' ? 'active' : ''
                      }`}
                    onClick={() => handleSectionClick('user')}
                  >
                    Users / Notes
                    <div className='float-end'>
                      <i className='ti ti-chevron-right' />
                    </div>
                  </div>
                  {/* <div
                    className={`list-group-item list-group-item-action px-6 py-4 border-0 ${activeSection === 'notes' ? 'active' : ''
                      }`}
                    onClick={() => handleSectionClick('notes')}
                  >
                    Notes
                    <div className='float-end'>
                      <i className='ti ti-chevron-right' />
                    </div>
                  </div> */}
                  <div
                    className={`list-group-item list-group-item-action px-6 py-4 border-0 ${activeSection === 'discussion' ? 'active' : ''
                      }`}
                    onClick={() => handleSectionClick('discussion')}
                  >
                    Discussion / Files
                    <div className='float-end'>
                      <i className='ti ti-chevron-right' />
                    </div>
                  </div>
                  {/* <div
                    className={`list-group-item list-group-item-action px-6 py-4 border-0 ${activeSection === 'files' ? 'active' : ''
                      }`}
                    onClick={() => handleSectionClick('files')}
                  >
                    File
                    <div className='float-end'>
                      <i className='ti ti-chevron-right' />
                    </div>
                  </div> */}

                  <div
                    className={`list-group-item list-group-item-action px-6 py-4 border-0 ${activeSection === 'calls' ? 'active' : ''
                      }`}
                    onClick={() => handleSectionClick('calls')}
                  >
                    Calls / Activity
                    <div className='float-end'>
                      <i className='ti ti-chevron-right' />
                    </div>
                  </div>

                  {/* <div
                    className={`list-group-item list-group-item-action px-6 py-4 border-0 ${activeSection === 'activity' ? 'active' : ''
                      }`}
                    onClick={() => handleSectionClick('activity')}
                  >
                    Activity
                    <div className='float-end'>
                      <i className='ti ti-chevron-right' />
                    </div>
                  </div> */}
                  {/* Repeat similar code for other sections */}
                </div>
              </div>
            </div>
            <div className='col-md-9'>
              <div id='general' ref={generalSectionRef}>
                <div className='card mb-6'>
                  <div className='card-header'>
                    <div className='d-flex justify-content-between align-items-center w-100'>
                      <div>
                        <h5>General</h5>
                      </div>
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-4 col-sm-4 mb-6'>
                        <div className='d-flex align-items-start'>
                          <div className='theme-avtar bg-primary'>
                            <i className='ti ti-mail' />
                          </div>
                          <div className='ms-2'>
                            <p className=' text-sm mb-0 fw-semibold'>Email</p>
                            <div className='mb-0  break-word' style={{ wordBreak: 'break-all' }}>
                              {LeadData?.email}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-md-4 col-sm-4 mb-6'>
                        <div className='d-flex align-items-start'>
                          <div className='theme-avtar bg-primary'>
                            <i className='ti ti-phone' />
                          </div>
                          <div className='ms-2'>
                            <p className=' text-sm fw-semibold mb-0'>Phone</p>
                            <div className='mb-0 fw'>{LeadData?.phone}</div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-4 mb-6'>
                        <div className='d-flex align-items-start'>
                          <div className='theme-avtar bg-primary'>
                            <i className='ti ti-test-pipe' />
                          </div>
                          <div className='ms-2'>
                            <p className=' text-sm fw-semibold mb-0'>Company Name </p>
                            <div className='mb-0 fw'>
                              {LeadData?.company_name ? LeadData?.company_name : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-4 mb-6 mt-4'>
                        <div className='d-flex align-items-start'>
                          <div className='theme-avtar bg-primary'>
                            <i className='ti ti-server' />
                          </div>
                          <div className='ms-2'>
                            <p className=' text-sm fw-semibold mb-0'>Stage</p>
                            <div className='mb-0 fw'>
                              {LeadData?.current_status ? LeadData?.current_status : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-4 mb-6 mt-4'>
                        <div className='d-flex align-items-start'>
                          <div className='theme-avtar bg-primary'>
                            <i className='ti ti-calendar' />
                          </div>
                          <div className='ms-2'>
                            <p className=' text-sm fw-semibold mb-0'>Created</p>
                            <div className='mb-0 fw'>
                              {LeadData?.created_at ? DateFormat(LeadData?.created_at) : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-4 col-sm-4 mb-6 mt-4'>
                        <div className='d-flex align-items-start'>
                          <div className='theme-avtar bg-primary'>
                            <i className='ti ti-calendar' />
                          </div>
                          <div className='ms-2'>
                            <p className=' text-sm fw-semibold mb-0'>Sources</p>
                            <div className='mb-0 fw'>
                              {LeadData?.source?.keyword}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className='col-md-4 col-sm-4 mb-6 mt-4'>
                        <div className='d-flex align-items-start '>
                          <div className='theme-avtar bg-primary'>
                            <i className='ti ti-chart-bar' />
                          </div>
                          <div className='ms-2 w-100'>
                            <h3 className='mb-0 text-primary'>{LeadData}</h3>
                            <div className='progress w-100'>
                              <div className='progress-bar bg-primary' style={{width: '25%'}} />
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>


                {/* <div className='col-xxl-12 mb-6'>
                  <div className='row'>
                    <div className='col-lg-6 col-6'>
                      <div className='card report_card'>
                        <div className='card-body'>
                          <div className='row align-items-center justify-content-between'>
                            <div className='col-auto mb-3 mb-sm-0'>
                              <small className='text-muted'>Source</small>
                              <h3 className='mb-0'>
                                {LeadData?.source_id ? LeadData?.source_id : '-'}
                              </h3>
                            </div>
                            <div className='col-auto'>
                              <div className='theme-avtar bg-success'>
                                <i className='ti ti-social text-white' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-6'>
                      <div className='card report_card'>
                        <div className='card-body'>
                          <div className='row align-items-center justify-content-between'>
                            <div className='col-auto mb-3 mb-sm-0'>
                              <small className='text-muted'>Files</small>
                              <h3 className='mb-0'>{LeadData?.files ? LeadData?.files : '-'}</h3>
                            </div>
                            <div className='col-auto'>
                              <div className='theme-avtar bg-primary'>
                                <i className='ti ti-file-invoice  text-white' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div id='user' ref={userSectionRef}>
                <div className='col mb-5'>
                  <div className='col-md-12 mb-5'>
                    <div className='card table-card deal-card'>
                      <div className='card-header'>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                          <div>
                            <h5>Users</h5>
                          </div>
                          <div className=''>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={<Tooltip>Add Users</Tooltip>}
                            >
                              <span className='symbol symbol-40px' onClick={openUserModal}>
                                <span className='symbol-label fs-5 fw-bold  bg-primary '>
                                  <i className='bi text-white  bi-plus-lg'></i>
                                </span>
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className='card-body py-0 table-border-style bg-none '
                        style={{ overflow: 'auto' }}
                      >
                        <div className=''>
                          <table className='table align-items-center mt-2'>
                            <tbody className='list'>
                              {LeadData?.assigned_users?.map((item: any) => (
                                <tr className='d-flex align-items-center justify-content-between'>
                                  <td className=' w-100'>
                                    <div className='w-40px h-40px rounded-circle bg-primary '>
                                      <img
                                        src={item?.img}
                                        width={30}
                                        className='avatar-sm rounded-circle'
                                      />
                                    </div>
                                  </td>
                                  <td className=' w-100'>
                                    <span className=''>{item?.name}</span>
                                  </td>
                                  <td>
                                    <div
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                      onClick={e => OnUserDelete(item)}
                                    >
                                      <KTIcon iconName='trash' className='fs-3' />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-md-6'>
                    <div className='card table-card'>
                      <div className='card-header'>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                          <div>
                            <h5>Sources</h5>
                          </div>
                           <div className=''>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={<Tooltip>Add Sources</Tooltip>}
                            >
                              <span className='symbol symbol-40px' onClick={openSourcesModal}>
                                <span className='symbol-label fs-5 fw-bold  bg-primary '>
                                  <i className='bi text-white  bi-plus-lg'></i>
                                </span>
                              </span>
                            </OverlayTrigger>
                          </div> 
                        </div>
                      </div>
                      <div
                        className='card-body pt-0 table-border-style bg-none'
                        style={{ overflow: 'auto' }}
                      >
                        <div className=''>
                          <table className='table align-items-center mb-0'>
                            <tbody className='list'>
                              //  {LeadData?.source_id?.map((item: any) => (
                              //   <tr>
                              //     <td>
                              //       <span className=''>{item?.name}</span>
                              //     </td>
                              //     <td>
                              //       <div
                              //         className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              //         onClick={() => OnSourceDeleteClick(item?.id)}
                              //       >
                              //         <KTIcon iconName='trash' className='fs-3' />
                              //       </div>
                              //     </td>
                              //   </tr>
                              // ))} 
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div id='notes' ref={notesSectionRef}> */}
              <div className='row pt-2 mb-6'>
                <div className='col-md-12' >
                  <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h5>Notes</h5>
                        <div className='col-6 text-end'></div>
                      </div>
                    </div>
                    <div className='card-body position-relative' style={{ height: 300 }}>
                      <div className='position-relative'>
                        <textarea
                          placeholder='Notes'
                          className='form-control form-control-lg form-control-solid'
                          name='notes'
                          id="inputNotes"
                          autoComplete='off'
                          rows={7}
                          value={note}
                          onChange={e => setNotes(e.target.value)}
                        />
                        <div className='position-absolute' style={{ width: "20px", height: "20px", top: "10px", right: "10px" }} onClick={updateLead}>
                          <FaRegSave size={20} className={isNoteChange() ? "text-primary" : "text-secondary"} />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}

              <div id='discussion' ref={discussionSectionRef}>
                <div className='row pt-2 mb-6'>
                  <div className='col-12'>
                    <div className='card table-card'>
                      <div className='card-header'>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                          <div>
                            <h5>Discussion</h5>
                          </div>
                          <div className=''>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={<Tooltip>Add Discussion</Tooltip>}
                            >
                              <span className='symbol symbol-40px' onClick={openDiscussionModal}>
                                <span className='symbol-label fs-5 fw-bold  bg-primary '>
                                  <i className='bi text-white  bi-plus-lg'></i>
                                </span>
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className='card-body table-border-style bg-none'
                      >
                        {LeadData?.lead_discussion?.map((item: any) => (
                          <tr className='d-flex align-items-center justify-content-between'>
                            <td className=' w-100'>
                              <span className=''>{item?.message}</span>
                            </td>
                            <td>
                              <div
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                onClick={e => OnDiscussionDeleteClick(item.id)}
                              >
                                <KTIcon iconName='trash' className='fs-3' />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-6'>
                    <div className='card table-card'>
                      <div className='card-header'>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                          <div>
                            <h5>Email</h5>
                          </div>
                          <div className=''>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={<Tooltip>Add Email</Tooltip>}
                            >
                              <span className='symbol symbol-40px' onClick={openUserModal}>
                                <span className='symbol-label fs-5 fw-bold  bg-primary '>
                                  <i className='bi text-white  bi-plus-lg'></i>
                                </span>
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className='card-body table-border-style bg-none'
                        style={{ height: 300, overflow: 'auto' }}
                      >
                        <ul className='list-unstyled list-unstyled-border'></ul>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* <div id='files' ref={fileSectionRef}> */}
              <div className='row pt-2 mb-6'>
                <div className='col-12'>
                  <div className='card table-card'>
                    <div className='card-header'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h5>Files</h5>
                        <div className='col-6 text-end'></div>
                      </div>
                    </div>

                    <div className='card-body'>
                      <div className='card-body bg-none'>
                        <input type='file' id='dropzonewidget2' className='d-none' onChange={e => uploadFile(e)} />
                        <label className='  w-100 ' htmlFor='dropzonewidget2'>
                          <div className=' dropzone  text-center w-100 justify-content-center'>
                            <span>Select files here to upload</span>
                          </div>
                        </label>
                      </div>

                      {LeadData?.files?.map((item: any) => (
                        <tr className='d-flex align-items-center justify-content-between'>
                          <td className=' w-100'>
                            <span className=''>{item?.filename}</span>
                          </td>
                          <td>
                            <div
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              onClick={e => { setFileDeleteModel(true); setFileSelectedId(item.id) }}
                            >
                              <KTIcon iconName='trash' className='fs-3' />
                            </div>
                          </td>
                        </tr>))}
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
              <div id='calls' ref={callsSectionRef}>
                <div className='row pt-2 mb-6'>
                  <div className='col-12'>
                    <div className='card table-card'>
                      <div className='card-header'>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                          <div>
                            <h5>Calls</h5>
                          </div>
                          <div className=''>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={<Tooltip>Add calls</Tooltip>}
                            >
                              <span className='symbol symbol-40px' onClick={openCallModal}>
                                <span className='symbol-label fs-5 fw-bold  bg-primary '>
                                  <i className='bi text-white  bi-plus-lg'></i>
                                </span>
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div className='  table-border-style'>
                        <TablesWidget
                          data={LeadData?.calls}
                          columns={columnData}
                          handlePerRowsChange={handlePerRowsChange}
                          handlePageChange={handlePageChange}
                          totalRows={TotalData}
                          showPagination={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='row mb-6' id='activity' ref={activitySectionRef}> */}
              <div>
                <div className='col-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h5>Activity</h5>
                        <div className='col-6 text-end'></div>
                      </div>
                    </div>

                    <div className='card-body height-450 '>
                      <div
                        className='row'
                        style={{ height: '350px !important', overflowY: 'scroll' }}
                      >
                        {LeadData?.activities?.map((item: any) => (
                          <div className='card p-4 shadow-sm'>
                            <h6 className='m-0'>{item?.activitie}</h6>
                            <small className='text-muted'> {item?.activitie}</small>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeadDetails
