import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { KTSVG } from '../../../../_metronic/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUserListApi } from '../../../redux/slices/UserSlices'


import Select from 'react-select'
import { createLeadUserApi } from '../../../redux/slices/LeadSlices'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'



const customStyles = {
  menu: (base: any) => ({
    ...base,
    borderRadius: ' 6px',
    marginTop: '0px',
    fontSize: '12px',
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: 'transparent',

    borderColor: state.isFocused ? 'transparent' : 'transparent',
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused ? 'transparent' : 'transparent',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#a1a5b7',
    fontSize: '14px',
  }),
  dropdownIndicator: (base: any, state: { selectProps: { menuIsOpen: any } }) => ({
    ...base,
    transition: 'all .4s ease',
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
}


interface PropType {
  open: any
  handleClose: any
  leadData: any
}

const UserForm = (props: PropType) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch<any>()

  const fetchAllUserList = () => {
    dispatch(getAllUserListApi())
  }

  const userList = useSelector((state: any) => state?.user?.AllUserListData?.data?.data)

  const userListOption = userList?.map((item: any) => ({
    value: item.id,
    label: item.name,
  }))



  useEffect(() => {
    fetchAllUserList()
  }, [])


  const initialValues = {
    "user_id": ""
  }

  const FormSchema = Yup.object().shape({
    user_id: Yup.string().required('user is required'),
  })



  const enableReinitialize = true

  const formik = useFormik({
    enableReinitialize,
    initialValues,
    validationSchema: FormSchema,

    onSubmit: async (values, { resetForm, setSubmitting }) => {

      const payload = {
        'user_id': values.user_id,
        'lead_id': props.leadData.id
      }


      const result = await dispatch(createLeadUserApi(payload))

      console.log(result)

      if (result.payload?.data.success) {
        setLoading(false)
        toast.success(result.payload.data.reason)
        props.handleClose()
        // formik.resetForm()
      }
      else {
        if (typeof result.payload.data.reason == 'string') {
          toast.error(result.payload.data.reason)
        }
        else {
          const reason = Object.values(result.payload.data.reason)
          reason.map((item: any) => {
            toast.error(item)
          })
        }
      }



    }

  })






  return (
    <Modal
      show={props.open}
      onHide={props.handleClose}
      size='lg'
      centered
      backdrop='static'
      keyboard={false}
    >
      <div className='modal-content'>
        <div className='modal-body px-8 pt-11 pb-0 '>
          <div className=' mb-7 d-flex justify-content-between border-0'>
            <div className=''>
              <h2> Add New </h2>
            </div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              onClick={props.handleClose}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>

          <form onSubmit={formik.handleSubmit} noValidate>
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bold text-dark'>User</label>
              <Select
                {...formik.getFieldProps('user_id')}
                options={userListOption}
                className='form-control form-control-sm form-control-solid p-1'
                classNamePrefix='react-select'
                placeholder='Select User'
                value={userListOption?.filter((item: any) => item.value == formik.values.user_id)}
                onChange={(selectedOption) => formik.setFieldValue('user_id', selectedOption.value)}
                styles={customStyles}
                onBlur={formik.handleBlur('user_id')}
              />
              {formik.touched.user_id && formik.errors.user_id && (
                <div className='fv-plugins-message-container mt-2'>
                  <div className='fv-help-block'>
                    <span role='alert'>{`${formik.errors.user_id}`}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='my-5 text-center'>
              <button type='submit' className='btn btn-lg btn-primary px-7 mb-5 '
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'> Submit</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export { UserForm }
