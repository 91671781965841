export function DateFormat(dateTime: any) {
  if (!dateTime) {
    return ''; // Return an empty string if dateTime is not provided
  }

  let dateTimeParts = dateTime?.split('T');
  let datePart = dateTimeParts?.[0];
  let dateTimeObj = new Date(datePart);

  // Extract day, month, and year
  let day = dateTimeObj.getDate().toString().padStart(2, '0');
  let month = (dateTimeObj.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  let year = dateTimeObj.getFullYear();

  return day + '/' + month + '/' + year;
}
