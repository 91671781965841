import instance from './instance'

export const createUser = (payload: any) => instance.post('register', payload)

export const getUserList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`userList?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateUser = (id: any, payload: any) => instance.post(`userUpdate/${id}`, payload)

export const getUserById = (id: number) => instance.get(`userList/${id}`)

export const getAllOrganizations = () => instance.get(`organization`)

export const getAllUserList = () => instance.get(`userList`)
