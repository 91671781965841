import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getAllPermissionList, insertPermission, insertPermissionByOrgId} from '../../services/PermissionService'

interface PermissionState {
  PermissionList: any
}

const initialState: PermissionState = {
  PermissionList: null,
}

export const insertPermissionApi = createAsyncThunk(
  'AllPermissionList/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await insertPermission()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const insertPermissionbyOrgIdApi = createAsyncThunk(
  'PermissionList/api/get',
  async (id:any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await insertPermissionByOrgId(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getAllPermissionListApi = createAsyncThunk(
  'AllPermissionList/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getAllPermissionList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const PermissionSlice = createSlice({
  name: 'Permission',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllPermissionListApi.fulfilled, (state, action) => {
      state.PermissionList = action.payload
    })
  },
})

export default PermissionSlice
