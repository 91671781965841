import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {DeleteFile, UploadFile, createAssignLead, createCalls, deleteAssignLead, deleteCalls, getLeadList} from '../../services/LeadService'
import {getLeadById} from '../../services/LeadService'
import {createLead} from '../../services/LeadService'
import {updateLead} from '../../services/LeadService'
import {deleteLead} from '../../services/LeadService'
import {createLeadDiscussion} from '../../services/LeadService'
import {deleteLeadDiscussion} from '../../services/LeadService'
import {getTagsList} from '../../services/LeadService'
import {getTagsById} from '../../services/LeadService'
import {createTags} from '../../services/LeadService'
import {updateTags} from '../../services/LeadService'
import {deleteTags} from '../../services/LeadService'
import { create } from 'domain'

interface GetLeadListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface LeadState {
  LeadList: any
  LeadData: any
  createLeadData: any
  updateLeadData: any
  userCreated: any
  userDeleted: any
  discussionDeleted: any
  discussionCreated: any
  callCreated: any
  callDeleted: any
  fileuploaded: any
  fileDeleted: any
  // -------------------
}

const initialState: LeadState = {
  LeadList: null,
  LeadData: null,
  createLeadData: null,
  updateLeadData: null,
  userCreated: null,
  userDeleted: null,
  discussionDeleted: null,
  discussionCreated: null,
  callCreated:null,
  callDeleted: null,
  fileuploaded:null,
  fileDeleted:null,
  // -------------------
}

export const createLeadApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
      const result = await createLead(payload)
      console.log(result)
      if(result.data.success)
      return result
      else
      return rejectWithValue(result.data)
  }
)

export const getLeadListApi = createAsyncThunk(
  'LeadList/api/get',
  async (
    {pageNumber, pageSize, filterkey}: GetLeadListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getLeadList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getLeadByIdApi = createAsyncThunk(
  'LeadById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getLeadById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateLeadApi = createAsyncThunk(
  'updateLead/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    
      const result = await updateLead(id, payload)
      if(result.data.success)
      return result
      else
      return rejectWithValue(result.data)
  }
)

export const deleteLeadApi = createAsyncThunk(
  'Lead/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteLead(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


// ---------------------- User ------------------------

export const createLeadUserApi = createAsyncThunk(
  'create-lead-user/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createAssignLead(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteLeadUserApi = createAsyncThunk(
  'delete-lead-user/api/post',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteAssignLead(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)





// ----------------------- discussion ---------------------------


export const createLeadDiscussionApi = createAsyncThunk(
  'discussion/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createLeadDiscussion(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteLeadDiscussionApi = createAsyncThunk(
  'LeadDiscussion/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteLeadDiscussion(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

// ------------------ calls API
export const createCallLeadApi = createAsyncThunk(
  'create-call/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createCalls(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



export const deleteCallLeadApi = createAsyncThunk(
  'delete-call/api/post',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteCalls(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const UploadFileApi = createAsyncThunk(
  'file-upload/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await UploadFile(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteFileLeadApi = createAsyncThunk(
  'file-delete/api/post',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await DeleteFile(id)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)




// -------------------------- tags ------------------------------


export const createTagsApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createTags(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getTagsListApi = createAsyncThunk(
  'TagsList/api/get',
  async (
    _,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getTagsList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getTagsByIdApi = createAsyncThunk(
  'TagsById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getTagsById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateTagsApi = createAsyncThunk(
  'updateTags/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateTags(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteTagsApi = createAsyncThunk(
  'Tags/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteTags(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
// -------------------- Assign Lead To user ---------------------
// -------------------------- calls ------------------------------


export const LeadSlice: any = createSlice({
  name: 'Lead',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getLeadListApi.fulfilled, (state, action) => {
        state.LeadList = action.payload
      })
      .addCase(getLeadByIdApi.fulfilled, (state, action) => {
        state.LeadData = action.payload
      })
      .addCase(createLeadApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createLeadData = action?.payload
      })
      .addCase(updateLeadApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateLeadData = action?.payload
      })
      .addCase(createLeadUserApi.fulfilled, (state, action) => {
        state.userCreated = action?.payload
      })
      .addCase(deleteLeadUserApi.fulfilled, (state, action) => {
        state.userDeleted = action?.payload
      })
      .addCase(deleteLeadDiscussionApi.fulfilled, (state, action) => {
        state.discussionDeleted = action?.payload
      })
      .addCase(createLeadDiscussionApi.fulfilled, (state, action) => {
        state.discussionCreated = action?.payload
      })
      .addCase(createCallLeadApi.fulfilled, (state, action) => {
        state.callCreated = action?.payload
      })
      .addCase(deleteCallLeadApi.fulfilled, (state, action) => {
        state.callDeleted = action?.payload
      })
      .addCase(UploadFileApi.fulfilled, (state, action) => {
        state.fileuploaded = action?.payload
      })
      .addCase(deleteFileLeadApi.fulfilled, (state, action) => {
        state.fileDeleted = action?.payload
      })

  },
})
export default LeadSlice.reducer
