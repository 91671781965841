import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {useTheme} from '../../layout/app-theme/ThemeContext'

interface PropType {
  open: any
  handleClose: any
  icon: string
  title: string
  description: string
  onSave: () => void
}

const ConfirmationModal = (props: PropType) => {
  const {theme} = useTheme()

  return (
    <Modal show={props.open} onHide={props.handleClose}>
      <div className='modal-content'>
        {/* <div>
        {props?.initialLocation?.name}
      </div> */}

        <div className='modal-header pb-2 justify-content-end border-0'>
          <div
            className={`btn btn-icon btn-sm btn-active-light-${theme} ms-2`}
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body py-0 text-center'>
          <div
            className={`m-auto text-center d-flex align-items-center justify-content-center border border-${theme} border-4 rounded-circle p-4`}
            style={{width: '90px', height: '90px'}}
          >
            <KTSVG path={props.icon} className={`svg-icon svg-icon-5x text-${theme}`} />
          </div>

          <div className='py-4 mt-5'>
            <h1>{props.title}</h1>
            <p className='mt-4 fw-bold text-muted fs-6'>{props.description}</p>
          </div>
        </div>
        <div className='modal-footer mb-5 border-0 justify-content-center'>
          <button
            type='button'
            className='btn btn-light'
            data-bs-dismiss='modal'
            onClick={props.handleClose}
          >
            Cancel
          </button>
          <button
            type='button'
            className={`btn btn-light-${theme}`}
            data-bs-dismiss='modal'
            onClick={() => {
              props.handleClose()
              props.onSave()
            }}
          >
            <div className='px-4'>Yes</div>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {ConfirmationModal}
