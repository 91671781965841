/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
}) => {
  return (
    <a href='#' className={`card bg-${color} hoverable ${className}`}>

      <div className='card-body'>
        <div className={`rounded bg-primary d-flex justify-content-center align-items-center`} style={{ width: "50px", height: "50px" }}>
          <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />
        </div>

        <div className='d-flex justify-content-between fs-3  mt-5 flex-row-reverse'>
          <div className={`text-${titleColor} fw-bold `}>{title}</div>
          <div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
        </div>
      </div>
    </a>
  )
}

export { StatisticsWidget5 }
