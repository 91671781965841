import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { createLeadDiscussionApi, createLeadUserApi } from '../../../redux/slices/LeadSlices'
import { useFormik } from 'formik'
import * as Yup from 'yup'


interface PropType {
    open: any
    handleClose: any
    leadData: any
}



const DiscussionForm = (props: PropType) => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch<any>()

    const initialValues = {
        "discussion": ""
    }

    const FormSchema = Yup.object().shape({
        discussion: Yup.string().required('discussion is required'),
    })



    const enableReinitialize = true

    const formik = useFormik({
        enableReinitialize,
        initialValues,
        validationSchema: FormSchema,

        onSubmit: async (values, { resetForm, setSubmitting }) => {

            const payload = {
                'message': values.discussion,
                'lead_id': props.leadData.id
            }


            const result = await dispatch(createLeadDiscussionApi(payload))

            console.log(result)

            if (result.payload?.data.success) {
                setLoading(false)
                toast.success(result.payload.data.reason)
                props.handleClose()
                // formik.resetForm()
            }
            else {
                if (typeof result.payload.data.reason == 'string') {
                    toast.error(result.payload.data.reason)
                }
                else {
                    const reason = Object.values(result.payload.data.reason)
                    reason.map((item: any) => {
                        toast.error(item)
                    })
                }
            }
        }

    })

    return (
        <Modal
            show={props.open}
            onHide={props.handleClose}
            size='lg'
            centered
            backdrop='static'
            keyboard={false}
        >
            <div className='modal-content'>
                <div className='modal-body px-8 pt-11 pb-0 '>
                    <div className=' mb-7 d-flex justify-content-between border-0'>
                        <div className=''>
                            <h2> Add New </h2>
                        </div>
                        <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            onClick={props.handleClose}
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>

                        <div className='fv-row mb-10'>
                            <label className='form-label fs-6 fw-bold text-dark'>Discussion</label>
                            <textarea
                                // placeholder='Message'
                                {...formik.getFieldProps('discussion')}
                                className='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                                placeholder='Enter Discussion'
                                rows={4}
                            />
                            {formik.touched.discussion && formik.errors.discussion && (
                                <div className='fv-plugins-message-container mt-2'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{`${formik.errors.discussion}`}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='my-5 text-center'>
                            <button type='submit' className='btn btn-lg btn-primary px-7 mb-5 '
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className='indicator-label'> Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export { DiscussionForm }