import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { createCallLeadApi, createLeadDiscussionApi, createLeadUserApi } from '../../../redux/slices/LeadSlices'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'


interface PropType {
    open: any
    handleClose: any
    leadData: any
}



const customStyles = {
    menu: (base: any) => ({
      ...base,
      borderRadius: ' 6px',
      marginTop: '0px',
      fontSize: '12px',
    }),
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      backgroundColor: 'transparent',
  
      borderColor: state.isFocused ? 'transparent' : 'transparent',
      boxShadow: 'none',
      '&:hover': {
        borderColor: state.isFocused ? 'transparent' : 'transparent',
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#a1a5b7',
      fontSize: '14px',
    }),
    dropdownIndicator: (base: any, state: { selectProps: { menuIsOpen: any } }) => ({
      ...base,
      transition: 'all .4s ease',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    }),
  }
  



const CallForm = (props: PropType) => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch<any>()

    const initialValues = {
        "subject": "",
        "call_type": "",
        "duration": null,
    }

    const FormSchema = Yup.object().shape({
        subject: Yup.string().required('subject is required'),
        call_type: Yup.string().required('Call type is required'),
        duration: Yup.string().required('Call duration is required')
    })



    const enableReinitialize = true
    const formik = useFormik({
        enableReinitialize,
        initialValues,
        validationSchema: FormSchema,

        onSubmit: async (values, { resetForm, setSubmitting }) => {

            const payload = {
                'subject': values.subject,
                "call_type": values.call_type,
                "duration": values.duration,
                'lead_id': props.leadData.id
            }

            const result = await dispatch(createCallLeadApi(payload))
            console.log(result)

            if (result.payload?.data.success) {
                setLoading(false)
                toast.success(result.payload.data.reason)
                props.handleClose()
                // formik.resetForm()
            }
            else {
                if (typeof result.payload.data.reason == 'string') {
                    toast.error(result.payload.data.reason)
                }
                else {
                    const reason = Object.values(result.payload.data.reason)
                    reason.map((item: any) => {
                        toast.error(item)
                    })
                }
            }
        }

    })

    return (
        <Modal
            show={props.open}
            onHide={props.handleClose}
            size='lg'
            centered
            backdrop='static'
            keyboard={false}
        >
            <div className='modal-content'>
                <div className='modal-body px-8 pt-11 pb-0 '>
                    <div className=' mb-7 d-flex justify-content-between border-0'>
                        <div className=''>
                            <h2> Add New </h2>
                        </div>
                        <div
                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                            onClick={props.handleClose}
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>

                        <div className='fv-row mb-10'>
                            <label className='form-label fs-6 fw-bold text-dark'>Subject</label>
                            <input
                                // placeholder='Message'
                                {...formik.getFieldProps('subject')}
                                placeholder='Enter Subject'
                                className='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                            />
                            {formik.touched.subject && formik.errors.subject && (
                                <div className='fv-plugins-message-container mt-2'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{`${formik.errors.subject}`}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className='fv-row mb-10'>
                            <label className='form-label fs-6 fw-bold text-dark'>Call Type</label>
                            <Select
                                 {...formik.getFieldProps('call_type')}
                                options={[{"label":"Casual","value":0},{"label":"Query","value":1}]}
                                className='form-control form-control-sm form-control-solid p-1'
                                classNamePrefix='react-select'
                                placeholder='Select Call Type'
                                onChange={(selectedOption) => formik.setFieldValue('call_type', selectedOption.label)}
                                styles={customStyles}
                                onBlur={formik.handleBlur('call_type')}
                            />
                            {formik.touched.call_type && formik.errors.call_type && (
                                <div className='fv-plugins-message-container mt-2'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{`${formik.errors.call_type}`}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='fv-row mb-10'>
                            <label className='form-label fs-6 fw-bold text-dark'>Duration</label>
                            <input
                                // placeholder='Message'
                                {...formik.getFieldProps('duration')}
                                type='number'
                                placeholder='Enter in hours'
                                className='form-control form-control-lg form-control-solid'
                                autoComplete='off'
                            />
                            {formik.touched.duration && formik.errors.duration && (
                                <div className='fv-plugins-message-container mt-2'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{`${formik.errors.duration}`}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='my-5 text-center'>
                            <button type='submit' className='btn btn-lg btn-primary px-7 mb-5 '
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className='indicator-label'> Submit</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export { CallForm }