import instance from './instance'

export interface ModuleAdd {
  organization_id: string,
  module_id: Array<number>
}

export const createOrganization = async (payload: any) => await instance.post('organization', payload)

export const getOrganizationList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`organization?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateOrganization = async (id: any, payload: any) => await instance.post(`organization/${id}`, payload)

export const deleteOrganization = (id: any) =>
  instance.delete(`organization/${id}`)


export const getOrganizationSettingById = (id: number) => instance.get(`getorganization/${id}`)

export const createOrganizationSetting = async (payload: any) => await instance.post('organizationsetting', payload)
  
export const updateOrganizationSetting = async (id: string,payload:any) => await instance.post(`organizationsetting/${id}`,payload)




export const getOrganizationById = (id: number) => instance.get(`organization/${id}`)

export const createOrganizationModule = async (payload: ModuleAdd) => await instance.post('organizationmodules', payload)
  
export const updateOrganizationModule = async (id: string,payload:any) => await instance.post(`organizationmodules/${id}`,payload)

export const getOrganizationModuleList = async (id: any) => await instance.get(`organizationby/${id}`)

export const deleteOrganizationModule = async (id: any) => await instance.delete(`organizationmodules/${id}`)
