import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import LeadDetails from '../modules/Lead/components/LeadDetails'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UserPage = lazy(()=> import('../modules/user-management/UserPage'))
  const Setting = lazy(() => import('../modules/setting/SettingPage'))
  const Lead = lazy(() => import('../modules/Lead/LeadPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <Setting />
            </SuspensedView>
          }
        />

<Route
          path='leads/*'
          element={
            <SuspensedView>
              <Lead />
            </SuspensedView>
          }
        />

        <Route
          path='leads/lead-details/:id'
          element={
            <SuspensedView>
              <LeadDetails />
            </SuspensedView>
          }
        /> <Route
        path='leads/*'
        element={
          <SuspensedView>
            <Lead />
          </SuspensedView>
        }
      />

      <Route
        path='leads/lead-details/:id'
        element={
          <SuspensedView>
            <LeadDetails />
          </SuspensedView>
        }
      />

        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              < UserPage/>
            </SuspensedView>
          }
        />
        
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
