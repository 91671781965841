import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import OrganizationSlice from './slices/OrganizationSlices'
import OrganizationLocationSlice from './slices/OrganizationLocationSlices'
import UserSlice from './slices/UserSlices'
import RoleSlice from './slices/RoleSlices'
import GroupSlice from './slices/GroupSlices'
import SystemCodeSlice from './slices/SystemCodeSlices'
import WorkflowSlice from './slices/WorkflowSlices'
import CustomSettingSlice from './slices/CustomSettingSlices'
import  ModuleSlice  from './slices/ModuleSlice'
import PermissionSlice from './slices/PermissionSlice'
import  {LeadSlice} from './slices/LeadSlices'

// Configure the store
export const store = configureStore({
  reducer: {
    organization: OrganizationSlice.reducer,
    organizationLocation: OrganizationLocationSlice.reducer,
    user: UserSlice.reducer,
    role: RoleSlice.reducer,
    group: GroupSlice.reducer,
    systemCode: SystemCodeSlice.reducer,
    workflow: WorkflowSlice.reducer,
    customSetting: CustomSettingSlice.reducer,
    lead: LeadSlice.reducer,
    module: ModuleSlice.reducer,
    permission: PermissionSlice.reducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

// Define RootState type
export type RootState = ReturnType<typeof store.getState>

// Define AppDispatch type
export type AppDispatch = typeof store.dispatch
