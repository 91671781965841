import React, {createContext, useState, useContext, ReactNode, FC} from 'react'

// Define the shape of the context value
interface ThemeContextValue {
  theme: string
  changeTheme: (newColor: string) => void
}

// Create the context with a default value
const ThemeContext = createContext<ThemeContextValue | undefined>(undefined)

// Create a custom hook to use the ThemeContext
export const useTheme = (): ThemeContextValue => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

// Define the props for the ThemeProvider component
interface ThemeProviderProps {
  children: ReactNode
}

// Create the provider component
export const ThemeProvider: FC<ThemeProviderProps> = ({children}) => {
  const [theme, settheme] = useState<string>('primary')

  const changeTheme = (newColor: string) => {
    settheme(newColor)
  }

  return <ThemeContext.Provider value={{theme, changeTheme}}>{children}</ThemeContext.Provider>
}
