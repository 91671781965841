import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useSelector, useDispatch } from 'react-redux'
import useSettingData from '../../../../helpers/components/UseSettingData'
import { useThemeMode } from '../../../../partials'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { mode } = useThemeMode() // Get the current theme mode from context
  let modules = localStorage.getItem("modules")
  const organizationSettingData = modules ? JSON.parse(modules) : []

  return (
    <div className={`sidebar-menu-main text-${mode === 'dark' ? 'light' : 'dark'}`}>



      {organizationSettingData
        ?.map((item: any) => {
          let path = `/${item.title.replace(" ", "-").toLowerCase()}`;
          if (item?.child?.length > 0) {
            return <SidebarMenuItemWithSub
              key={item.title}
              to={path}
              title={item.title}
              icon={item.icon}
            >
              {item.child?.map((row: any) => {
                let path = `/${item.title.replace(" ", "-").toLowerCase()}/` + row.title.replace(" ", "-").toLowerCase()
                if (row.display_on_board)
                  return <SidebarMenuItem
                    key={row.title}
                    to={path}
                    title={row.title}
                    icon={row.icon}
                    hasBullet={false}
                  />
              })}
            </SidebarMenuItemWithSub>
          } else {
            return (
              <SidebarMenuItem
                key={item.title}
                to={path}
                icon={item.icon}
                title={item.title}
              />
            )
          }
        })}


      <SidebarMenuItem
        key={"Lead"}
        to={"/leads"}
        title={"Leads"}
        icon={"briefcase"}
        hasBullet={false}
      />


    </div>
  )
}

export { SidebarMenuMain }




{/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/customized-form'
        icon='social-media'
        title='Custom Form'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItemWithSub to='/setting' title='Setting' icon='gear' fontIcon='bi-layers'>
        <SidebarMenuItem
          to='/setting/organization'
          title='Organization'
          icon='cheque'
          hasBullet={false}
        />
        <SidebarMenuItem
          to='/setting/location-organization'
          title='Organization Location'
          icon='delivery-geolocation'
          hasBullet={false}
        />
        <SidebarMenuItem to='/setting/user' title='User' icon='security-user' hasBullet={false} />
        <SidebarMenuItem to='/setting/group' title='Group' icon='people' hasBullet={false} />
        <SidebarMenuItem to='/setting/role' title='Role' icon='share' hasBullet={false} />
        <SidebarMenuItem
          to='/setting/system-code'
          title='System Code'
          icon='wrench'
          hasBullet={false}
        />
        <SidebarMenuItem
          to='/setting/holiday-list'
          title='Holiday List'
          icon='calendar'
          hasBullet={false}
        />
        <SidebarMenuItem to='/setting/workflow' title='Workflow' icon='theta' hasBullet={false} />
        <SidebarMenuItem to='/setting/module' title='Module' icon='file-sheet' hasBullet={false} />
      </SidebarMenuItemWithSub> */}